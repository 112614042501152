<template>
    <b-modal
        id="confirm-delete"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        v-model="modalShow"
    >
        <div class="d-flex flex-column align-items-center justify-center-center my-2">
            <feather-icon
                id="modal-confirm"
                :icon="iconModal"
                :class="'text-'+type+ ' mb-2'"
                size="80"
            />
            <h3>{{ action }}</h3>
            <span class="text-center">{{ infoTextModal }}</span>
            <div class="d-flex mt-2">
                <b-button :disabled="loading" variant="custom-blue" @click="confirmAction" >
                    <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Ok
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
export default {
    components: {
        BModal, BButton
    },

    props: {
        modalShow: {
            required: true,
            type: Boolean
        },
        typeModal: {
            required: true,
            type: String
        },
        infoText: {
            required: false,
            type: Object
        }
    },

    data() {
        return {
            loading: false,
            textComplement: '',
            modalTypes: [
                {
                    'type': 'success',
                    'icon': 'CheckCircleIcon',
                    'action': 'Transferência Aceita',
                    'textButtonCancel': 'Cancelar',
                    'infoText': 'Estoque já disponível na sua unidade.'
                },
                {
                    'type': 'warning',
                    'icon': 'AlertCircleIcon',
                    'action': 'Transferência Recusada',
                    'textButtonCancel': 'Cancelar',
                    'infoText': 'O recebimento da transferência foi recusado. As doses transferidas retornaram para análise na unidade de origem'
                },
                {
                    'type': 'danger',
                    'icon': 'AlertCircleIcon',
                    'action': 'Transferência cancelada',
                    'textButtonCancel': 'Cancelar',
                    'infoText': 'O recebimento da transferência foi cancelada. As doses transferidas retornaram para a unidade de origem'
                }
            ],
            type: '',
            action: '',
            iconModal: '',
            textButtonCancel: '',
            infoTextModal: ''
        }
    },

    methods: {
        confirmAction(modalEvent) {
            modalEvent.preventDefault();

            this.loading = true;

            this.$emit('confirmed', modalEvent);
        },

        cancelAction(modalEvent) {
            modalEvent.preventDefault();

            this.$emit('canceled', modalEvent);
        },

        choseText(defaultMesssage) {
            if(this.$props.infoText && typeof this.$props.infoText !== 'string') {
                return this.$props.infoText[this.$props.typeModal];
            }

            return this.$props.infoText ? this.$props.infoText : defaultMesssage;
        }
    },

    watch: {
        modalShow() {
            this.loading = false;

            this.modalTypes.map(item => {
                if(item.type == this.$props.typeModal) {
                    this.action = item.action;
                    this.textButtonCancel = item.textButtonCancel;
                    this.infoTextModal = this.choseText(item.infoText);
                    this.type = item.type;
                    this.iconModal = item.icon;

                }
            });
        }
    }
}
</script>

<style>

</style>