<template>
    <div class="content-wrapper">
        <PageHeader :screenName="'Transferência '+form.transferencia" :linkItems="linkItems"/>

        <ConfirmModalTransferencia
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="redirectToList"
        />
        
        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirectToList"
        />

        <ConfirmModalEstoque 
            :infoText="modalConfirm.infoText"
            :action="modalConfirm.action"
            :modalShow="modalConfirm.showModal"
            :typeModal="modalConfirm.typeModal"
            @canceled="closeModalConfirmEstoque"
            @confirmed="cancelTransferencia"
        />


        <main class="card">
            <section class="form p-2">
                <b-form>
                    <div class="py-1 mb-1">
                        <span class="font-weight-bold custom rounded-pill" :class="formatString(form.acoes.length ? form.acoes[form.acoes.length - 1].situacao : '')">
                            {{ form.acoes.length ? form.acoes[form.acoes.length - 1].situacao : ''}}
                        </span>
                    </div>
                    <b-row>
                        <b-col lg="6" md="12" sm="12">
                            <b-form-group label="Número da transferência"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.transferencia }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                            <b-form-group label="Número da Nota Fiscal"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.nota_fiscal }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                            <b-form-group label="Unidade Operacional de Origem"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.origem }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group> 
                             <b-form-group label="Unidade Operacional de Destino"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.destino }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group> 
                            <b-form-group label="Observações"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.observacao ? form.observacao : ' - ' }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group >
                                <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                                    <ul class="timeline">
                                        <li v-for="acao in form.acoes" :key="acao.id_movimentacao_situacao">
                                            <label class="font-weight-bold pl-5">{{formatDate(acao.cadastro, 'DD/MM/YYYY')}} - {{ acao.observacao }}</label>
                                            <p class="pl-5">
                                                {{ acao.observacao.includes('origem') ? form.origem : form.destino }} - {{ acao.criador }}
                                            </p>
                                        </li>
                                    </ul>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                    </b-row>
                </b-form>
            </section>
            <section v-if="table.items.length" class="list-itens-transferencia">
                    <b-table
                        id="tableItensTransferencia"
                        responsive
                        :stickyColumn="true"
                        :fields="table.fields"
                        :items="table.items"
                    >
                        <template #cell(vacina)="row">
                            <span class=" comprimento_maximo_celulas"
                            :title="row.item.vacina"
                            >
                                {{ row.item.vacina }}
                            </span>
                        </template>
                        <template #cell(lote)="row">
                            <span>
                                {{ row.item.numero_lote }}
                            </span>
                        </template>
                        <template #cell(validade)="row">
                            <span class="comprimento_maximo_celulas"
                            >
                                {{ formatDate(row.item.validade, 'DD/MM/YYYY') }}
                            </span>
                        </template>
                        <template #cell(fabricante)="row">
                            <span>
                                {{ row.item.fabricante.descricao }}
                            </span>
                        </template>
                        <template #cell(frasco)="row">
                            <span class="custom rounded-pill" :class="row.item.frasco.quantidade == 1 ? 'mono' : 'multi'">
                                {{ row.item.frasco.descricao }}
                            </span>
                        </template>
                        <template #cell(quantidade)="row">
                            <span class="comprimento_maximo_celulas"
                            >
                                {{ row.item.quantidade }}
                            </span>
                        </template>
                        <template #cell(valor_unitario)="row">
                            <span class="comprimento_maximo_celulas"
                            >
                                R${{ formatDotDecimal(row.item.valor_unitario) }}
                            </span>
                        </template>
                        <template #cell(valor_total)="row">
                            <span class="comprimento_maximo_celulas"
                            >
                                R${{ formatDotDecimal((row.item.quantidade * row.item.valor_unitario).toFixed(2)) }}
                            </span>
                        </template>
                    </b-table>
                    <div class="pb-2">
                        <b-row>
                            <b-col lg="12" md="12" sm="12" class="border-top d-flex justify-content-end px-3">
                                <div class="mr-2 text-center">
                                    <p class="mb-0 mt-1">
                                        Qt. Doses
                                    </p>
                                    <span class="font-weight-bold mb-0">
                                        {{ info.quantidade_total }}
                                    </span>
                                </div>
                                <div class="text-center">
                                    <p class="mb-0 mt-1">
                                        Valor Total R$
                                    </p>
                                    <span class="font-weight-bold mb-0">
                                        R${{ formatDotDecimal(info.valor_total) }}
                                    </span>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
            </section>
            <section class="m-2"
                v-if="form.situacao != ''
                && form.situacao != 'Cancelado'
                && form.situacao != 'Concluído'">
                <b-button id="cancel-transferencia"
                    variant="outline-warning"
                    @click.prevent="openModalConfirmEstoque"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar transferência</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, 
BFormRadio, BButton, BFormTextarea, BLink,
BTable, VBTooltip } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moment from 'moment'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import ConfirmModalTransferencia from '@/views/components/custom/modals/estoque/ConfirmModalTransferencia.vue'
import ConfirmModalEstoque from '@/views/components/custom/modals/estoque/ConfirmModalEstoque.vue'

export default {
    title: 'Detalhes transferência',
    components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BButton,
    PageHeader,
    VuePerfectScrollbar,
    ErrorModal,
    BLink,
    BTable,
    VBTooltip,
    ConfirmModalTransferencia,
    ConfirmModalEstoque
},

    data() {
        return{
            settings: {
                maxScrollbarLength: 50,
                wheelSpeed: 0.8,
                wheelPropagation: true
            },
            linkItems: [
                {
                    name: 'Estoque',
                    routeName: 'estoque-list'
                },
                {
                    name: 'Transferência',
                    routeName: 'transferencia-list'
                },
                {
                    name: 'Carregando dados...',
                    active: true
                }
            ],
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            modalConfirm: {
                showModal: false,
                typeModal: 'danger',
                infoText: 'As doses retornarão para a unidade de origem.',
                action: 'cancelar a transferência'
            },
            form: {
                transferencia: '',
                situacao: '',
                nota_fiscal: '',
                origem: '',
                destino: '',
                observacao: '',
                acoes: []
            },
            info: {
                quantidade_total: 0,
                valor_total: 0.00,
            },
            table: {
                fields: [
                    {key: "vacina", label: "VACINA", sortable: false, class: 'mw-300'},
                    {key: "lote", label: "LOTE", sortable: false, class: 'mw-150'},
                    {key: "validade", label: "VALIDADE", sortable: false, class: 'mw-100'},
                    {key: "fabricante", label: "FABRICANTE", sortable: false, class: 'mw-150'},
                    {key: "frasco", label: "FRASCO", sortable: false, class: 'mw-150'},
                    {key: "quantidade", label: "QT", sortable: false, class: 'mw-100'},
                    {key: "valor_unitario", label: "VALOR UN.", sortable: false, class: 'mw-100'},
                    {key: "valor_total", label: "VALOR TOTAL", sortable: false, class: 'mw-100'}
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'lote',
                order: 'desc'
            },
        }
    },
    mounted() {
    if(typeof this.$route.params.idTransferencia == "undefined") {
        this.openModalError();
        return;
    }
    this.$http.get(this.$api.movimentacaoParameter(this.$route.params.idTransferencia)).then(({ data }) => {
        this.preenchimentoFormulario(data);
        this.linkItems[2].name = 'Transferência #' + data.codigo;
    })
  },

    methods: {
        preenchimentoFormulario(dados){
            this.form.transferencia = '#'+dados.codigo;
            this.form.nota_fiscal = dados.numero_nota_fiscal;
            this.form.origem = dados.itens[0].lote_unidade_origem.unidade.descricao;
            this.form.destino = dados.itens[0].lote_unidade_destino.unidade.descricao;
            this.form.observacao = dados.observacao;
            this.form.acoes = dados.acoes;
            this.form.situacao = dados.situacao;
            let itens = [];

            dados.itens.map((item)=>{
                const valor_unitario = item.lote_unidade_origem.nota_fiscal_itens[0].valor_unitario_conversao;
                itens.push({
                    vacina: item.lote_unidade_origem.lote.vacina.nome_tecnico,
                    numero_lote: item.lote_unidade_origem.lote.numero_lote,
                    validade: item.lote_unidade_origem.lote.validade,
                    fabricante: item.lote_unidade_origem.lote.fabricante,
                    frasco: item.lote_unidade_origem.lote.frasco,
                    quantidade: item.quantidade,
                    valor_unitario: valor_unitario,
                    valor_total: item.quantidade * valor_unitario
                });
            });

            this.table.items = itens;
            this.updateQuantities();
        },
        updateQuantities(){
            let quantidadeTotal = 0, valorTotal = 0;
            this.table.items.map((item)=>{
                quantidadeTotal += item.quantidade;
                valorTotal += item.valor_total;
            });
            this.info.quantidade_total = parseInt(quantidadeTotal);
            this.info.valor_total = (parseFloat(valorTotal)).toFixed(2);
        },
        openModalError() {
            this.modalError.showModal = true;
        },
        openModalAction() {
            this.modal.showModal = true;
        },
        redirectToList() {
           this.$router.push({name: 'transferencia-list'});
        },
        formatString(string){
            const formatted = string.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z])/g, '');
            return formatted.toLowerCase();
        },
        formatDate(string, format){
            return moment(string).format(format);
        },
        formatDotDecimal(value){
            let string = value.toString();
            return string.replace('.', ',')
        },
        openModalConfirmEstoque() {
            this.modalConfirm.showModal = true;
        },
        closeModalConfirmEstoque() {
            this.modalConfirm.showModal = false;
        },
        cancelTransferencia() {
            this.$http.patch(this.$api.movimentacaoCancelaParameter(this.$route.params.idTransferencia)).then(() => {
                this.modalConfirm.showModal = false;
                this.openModalAction();
            });
        },
    }
}
</script>

<style scoped>
.custom{
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}

.pendente{
    background-color: #fff3e8;
    color: #ff9110;
}
.aguardando{
    background-color: #e5eef7;
    color: #2772c0;
}
.recusado{
    background-color: #fceaea;
    color: #ea5455;
}
.concluido{
    background-color: #e5f8ed;
    color: #28c76f;
}
.cancelado{
    background-color: #eaeaed;
    color: #6e6b7b;
}
.mono{
    background-color: #f3eeff;
    color: #8c65e2;
}
.multi{
    background-color: #e3f6f4;
    color: #21a696;
}
legend{
    color: #5E5873;
}

.altura_max_input_scrollbar{
    max-height:300px;
    word-break: break-all;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #b9b9c3;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}

ul.timeline > li {
    margin-bottom: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: #b9b9c3;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #f5f6f9;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}
ul.timeline > li:last-of-type:before {
    background: #2772c0!important;
}
</style>